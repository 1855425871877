<template>
  <div class="preview dark"
    v-loading="loading"
    element-loading-text="加载中..."
    :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="preview-header dark">
      <div class="breadcrumb-head">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item
            v-for="(n, i) in project.routes"
            :key="i">
            <span v-if="n.disable" style="cursor: pointer; color: #1eaaff" @click="jumpSpace(n)">
            {{n.name}}</span>
            <span v-else style="color: #b3bfc7">
            {{n.name}}</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="preview-body2">
      <el-scrollbar>
        <div class="frame common-frame dark">
          <div class="line"
               style="padding:24px 32px">
            <el-button-group>
              <el-button :type="filterType == n.value?'primary':''"
                         v-for="(n,i) in typeOption"
                         :key="i"
                         @click="chooseType(n)">{{n.name}}</el-button>
            </el-button-group>
          </div>
          <div class="line">
            <el-timeline>
              <el-timeline-item :timestamp="n.createDate"
                                :color="n.color"
                                placement="top"
                                v-for="(n,i) in list"
                                :key="i"
                                >
                <el-card>
                  <div class="opt-layet">
                    <img :src="n.head"
                         alt="">
                    <h4>{{n.userName}}</h4>
                    <p>{{n.content}}</p>
                  </div>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
        <empty v-if="list.length == 0" info="项目"
               style="height:70vh"></empty>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import _ from 'underscore';
import { prjBtnStatus, getSpaceOrPrjDetails, jumpRoute } from 'common/authBtn';
import Empty from 'components/common/forms/Empty';
export default {
  name: "OperationLog",
  components: {
    Empty
  },
  data () {
    return {
      loading: true,
      filterType: 1,
      list: [],
      title: '',
      project: {},
      usersOptions: [],
      typeOption: [
        {
          name: "全部类型",
          value: 1
        }, {
          name: "变更",
          value: 2
        }, {
          name: "成员",
          value: 3
        }
      ],
      roleList: [
        {
            id: 1,
            name: '拥有者'
          },{
            id: 2,
            name: '管理员'
          },
          {
            id: 3,
            name: '配置人员'
          },{
            id: 4,
            name: '开发人员'
          },{
            id: 5,
            name: '游客'
          }
      ],
      addAuth: false,
      editAuth: false,
      delAuth: false,
    }

  },
  mounted() {
    this.getAllUsers();
  },
  methods: {
    getDetail() {
      let self = this;
     getSpaceOrPrjDetails(self.$store).then((res) => {
        self.project = res;
        prjBtnStatus(res.id, self.$store, (result) => {
          self.addAuth = result.addAuth;
          self.editAuth = result.editAuth;
          self.delAuth = result.delAuth;
        });
        self.getDataFn();
      })
    },
    getAllUsers() {
      let self = this;
      self.$api.auth.selectAllUser().then((res) => {
        self.loading = false;
        self.usersOptions = res;
        self.getDetail();
      })
    },
    getDataFn() {
      let self = this;
      self.$api.sp.getPrjLogByType({type: self.filterType, prjId: self.project.id}).then((res) => {
        self.list = res;
        _.each(self.list, (v) => {
          v.head = require('assets/images/frame/default-head.svg');
          let user = _.filter(self.usersOptions, (u) => {
            return v.createBy == u.id;
          })
          if(user && user.length > 0) {
            v.userName = user[0].nickname;
          }
          v = self.setLogContent(v);
        })
        
      })
    },
    getTypeName(type) {
      let result = '';
      switch (parseInt(type)) {
        case 1:
          result = 'MYSQL';
          break;
      case 2:
          result = 'SQLSERVER';
          break;
        default:
          break;
      }
      return result;
    },
    setLogContent (obj) {
      let content = '', self = this;
      switch (obj.pgroup) {
        //项目
        case 1:
          switch (obj.operation) {
            case 1:
              content = '新增项目 ' + obj.name;
              obj.color = 'rgba(55, 250, 200, 1)';
              break;
            case 2:
              if(obj.oldName != '-1'&& obj.oldDescription != '-1')
                content = '编辑项目名称和描述： ' + obj.oldName + "修改为" + obj.name + " \n" + obj.oDescription + "修改为" + obj.description;
              else if(obj.oldName != '-1') 
                content = "编辑项目:" + obj.oldName + "修改为" + obj.name;
              else if(obj.oldDescription != '-1')
                content = '编辑项目描述: ' + obj.oldDescription + "修改为" + obj.description;
              else if(obj.oldDescription == '-1')
                content = '新增项目描述: ' + obj.description;
              obj.color = 'rgba(30, 170, 255, 1)';
              break;
            case 3:
              content = '删除项目 ' + obj.name;
              obj.color = 'rgba(250, 115, 0, 1)';
              break;
            default:
              break;
          }
          break;
        //成员
        case 2:
          let userName = '', roleName = '';
          let user = _.filter(self.usersOptions, (u) => {
            return u.id == obj.userId;
          })
          if(user && user.length > 0) {
            userName = user[0].nickname;
          }
          let role = _.filter(self.roleList, (r) => {
            return r.id == obj.roleId;
          })
          if(role && role.length > 0) {
            roleName = role[0].name;
          }
          switch (obj.operation) {
            case 1:
              content = '邀请 ' + userName + " 加入项目，角色为 " + roleName; 
              obj.color = 'rgba(55, 250, 200, 1)';
              break;
            case 2:
              let oldRoleName = '';
              let oldRole = _.filter(self.roleList, (r) => {
                return r.id == obj.oRoleId;
              })
              if(oldRole && oldRole.length > 0) {
                oldRoleName = oldRole[0].name;
              }
              if(obj.oRoleId != '-1'&& obj.oldExpireDate)
                content = '编辑成员: ' + userName + " 的角色由"+ oldRoleName +"修改为" + roleName + " /n" + "过期时间修改为" + obj.oldExpireDate;
              else if(obj.oRoleId != '-1')
                 content = '编辑成员: ' + userName + " 的角色由"+ oldRoleName +"修改为" + roleName;
              else 
                content = '编辑成员: ' + userName + " 过期时间修改为" + obj.oldExpireDate;

              obj.color = 'rgba(30, 170, 255, 1)';
              break;
            case 3: 
              content = '删除成员 ' + userName;
              obj.color = 'rgba(250, 115, 0, 1)';
              break;
            default:
              break;
          }
          break;
          //数据库
        case 3:
          switch (obj.operation) {
            case 1:
              content = '新增数据库 ' + obj.dbName;
              obj.color = 'rgba(55, 250, 200, 1)';
              break;
            case 2:
              content = '编辑数据库：';
              if(obj.oDbName != '-1')
                content = content + '数据库名称'+ obj.oDbName + "修改为" + obj.dbName + "/n"
              if(obj.oDbType != 0) 
                content = content + '数据库类型由' + self.getTypeName(obj.oDbType) + "修改为" + self.getTypeName(obj.dbType)
              if(obj.oUserName != '-1') 
                content = content + "用户名由" + obj.oUserName + "修改为" + obj.userName + '/n'
              if(obj.oPassword != '-1')
                content = content + '修改了密码' + '/n'
              if(obj.oPassword != '-1')
                content = content + "连接地址"+ obj.oUrl + "修改为" + obj.url + '/n'
              obj.color = 'rgba(30, 170, 255, 1)';
              break;
            case 3:
              content = '删除数据库 ' + obj.dbName;
              obj.color = 'rgba(250, 115, 0, 1)';
              break;
            default:
              break;
          }
          break;
        case 4:
          switch (obj.operation) {
            case 1:
              content = '新增全局参数 ' + obj.gKey;
              obj.color = 'rgba(55, 250, 200, 1)';
              break;
            case 2:
              content = '编辑全局参数： ';
              if(obj.oKey != '-1')
                content = content + 'key由'+ obj.oKey + "修改为" + obj.gKey + "/n"
              if(obj.oValue != '-1') 
                content = content + 'value由' + obj.oValue + "修改为" + obj.value
              obj.color = 'rgba(30, 170, 255, 1)';
              break;
            case 3:
              content = '删除全局参数 ' + obj.gKey;
              obj.color = 'rgba(250, 115, 0, 1)';
              break;
            default:
              break;
          }
          break;
        case 5:
          let verName = '版本';
          if(obj.type == 1)
            verName = '快照';
          switch (obj.operation) {
            
            case 1:
              content = `新增${verName} ` + obj.verName;
              obj.color = 'rgba(55, 250, 200, 1)';
              break;
            case 2:
              content = `编辑${verName}:`;
              if(obj.oVerName != '-1')
                content = content + `${verName}名称由`+ obj.oVerName + "修改为" + obj.verName + "/n"
              if(obj.oNo != '-1') 
                content = content + `${verName}编号由` + obj.oNo + "修改为" + obj.no
              if(obj.oVerDescription != '-1') 
                content = content + `${verName}描述由` + obj.oVerDescription + "修改为" + obj.verDescription
              var oFiles = !JSON.parse(obj.oFileIds)? [] :JSON.parse(obj.oFileIds), files = !JSON.parse(obj.fileIds) ? [] : JSON.parse(obj.fileIds);
              if(oFiles != '-1' && oFiles.length > 0 && files.length > 0)
                content = content + '修改了图片'
              else
                content = content + '清除图片'
              obj.color = 'rgba(30, 170, 255, 1)';
              break;
             case 3:
              content = `删除${verName} ` + obj.verName;
              obj.color = 'rgba(250, 115, 0, 1)';
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      obj.content = content;
      return obj;
    },
    chooseType (type) {
      this.filterType = type.value;
      this.getDataFn();
    },
    jumpSpace(n) {
      let self = this;
      if(self.project.id != n.id) {
        jumpRoute(self, n).then(() => {
          self.$router.push('/Main/SpaceDetail');
        })
      }
    },
    initData() {
      this.loading = true;
      this.list = [];
    },
  }
}
</script>

<style lang="scss" scoped>
.opt-layet {
  width: 100%;
  padding: 0 0 0 50px;
  display: inline-block;

  img {
    width: 40px;
    height: 40px;
    background: #ddd;
    border-radius: 50%;
    float: left;
    margin: 0 -50px;
  }

  h4,
  p {
    float: left;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h4 {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    line-height: 1;

    &:hover {
      color: rgba(115, 200, 255, 1);
    }
  }

  p {
    width: 100%;
    color: rgba(255, 255, 255, 0.8);
    cursor: default;
    line-height: 1.4;

    span {
      cursor: pointer;
      color: rgba(30, 170, 255, 1);

      &:hover {
        color: rgba(115, 200, 255, 1);
      }
    }
  }
}
</style>